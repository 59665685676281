/* AddUsers.module.css */
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.projectInfo {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  background-color:rgb(240, 242, 245);
}

.line {
  position: absolute; 
  top: 194px; 
  left: 600px; 
  width: 0px; 
  height: 228px; 
  border-width: 1px; 
  border-color: #BDC1CAFF; /* neutral-400 */
  border-style: solid; 
}

.line2 {
  position: absolute; 
  top: 192px; 
  left: 284px; 
  width: 1088px; 
  height: 0px; 
  border-width: 1px; 
  border-color: #BDC1CAFF; /* neutral-400 */
  border-style: solid; 
  transform: rotate(0deg); 
}

.projectDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.teamRoles {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 50px;
  /* width: 50%; */
}

.Managers {
  display: flex;
  justify-content: center;

}

.roleCount {
  background-color: #54808b;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 5px;
}

.userManagement {
  display: flex;
  justify-content: space-between;
}

.benchPool {
  flex: 1;
  
}

.projectMembers {
  margin-left: 20px;
}

.arrowButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px; /* Adjust spacing between buttons as needed */
}

.benchPool {
  margin-right: 40px;
}

.roleSelect {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.userList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user {
  display: flex;
  background-color:#DEE1E6FF;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}

.memberTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.memberTable th,
.memberTable td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.memberTable th {
  background-color: #f8f9fa;
  color: #6c757d;
  font-weight: 600;
}

.memberTable td {
  background-color: #ffffff;
}

.p-input-icon-right .pi {
  margin-right: 0.5rem;
}

.commentButton {
  margin-left: 0.5rem;
}

.costSalMargin {
  color: #28a745;
}

.costSalMargin span {
  display: block;
}




.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}
.flexJustifyBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userCard {
  width: 100%;
  background: #DEE1E6FF;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userCard p {
  margin: 0;
}

@media (min-width: 768px) {
  .userCard {
    height: 80px;
  }
}

@media (max-width: 767px) {
  .userCard {
    padding: 8px;
  }
}

