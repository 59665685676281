/* src/components/Sidebar.css */
.sidebar {
 
    width: 250px;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 10px;
    box-sizing: border-box;
  }

  .logo img {
    margin-bottom: 5px;
    height: 60px;
  }
  
  .logo h1 {
    font-size: 22px;
    margin: 0;
    padding: 0;
  }

  .logo {
    display: flex;
    justify-content: space-evenly;
    align-items: end;
    
  }
  
  .logo p {
    font-size: 14px;
    color: #888;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
  }
  
  nav ul li {
    margin: 18px 0;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
  nav ul li a span {
    margin-left: 10px;
  }
  
  nav ul li a.active-link {
    color: #ffffff;
    /* font-weight: bold; */
    background-color: #2c3e50; /* Matching the dark blue background */
    padding: 10px;
    border-radius: 5px;
  }
  
  nav ul li a.active-link svg {
    color: #ffffff;
  }
  