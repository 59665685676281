.container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    height: calc(100vh - 132px);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(128, 128, 128, 0.211);
    margin-top: 10px;
    overflow: scroll;
    position: relative;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    width: 48%;
  }
  
  .field {
    margin-bottom: 10px;
  }
  
  .field label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .field input, 
  .field select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .managersList {
    margin-top: 20px;
  }
  
  .manager {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .role {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .role input {
    width: 30%;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .role button {
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end; /* Ensures content is aligned to the right */
    margin-top: 20px;
    bottom: 10%;
    width: 90%; /* Adjust if necessary to fit the layout */
}
  
.buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

  .buttons .save {
    background-color: #007bff;
    color: #fff;
  }
  
  .buttons .cancel {
    background-color: #dc3545;
    color: #fff;
  }
  