.custom-datatable .p-datatable-thead > tr > th {
    border: 1px solid #ccc; /* Border for header columns */
    font-size: 16px; /* Set font size for table header */
    background-color: #f1f1f1; /* Optional: background color for header */
}

.custom-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #ccc; /* Border for data columns */
    font-size: 14px; /* Set font size for table data */
}


.table-container {
    margin: 20px;
    padding: 10px;
  }
   
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
   
  th, td {
    padding: 8px;
    border: 1px solid #ddd;
  }
   
  th {
    background-color: #f4f4f4;
  }
   
  h2 {
    text-align: center;
  }

  .leave-table {
    width: 30%;
    border-collapse: collapse;
    padding-right: 10px;
    
  }
   
  .leave-table th,
  .leave-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }
   
  .leave-table th {
    background-color: lightgray;
  }