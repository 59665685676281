/* Container for the leave sections */
.container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

/* Styling for each leave section */
.leave-section {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    /* background-color: #f9f9f9; */
    background-color: #fbfcfc;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.holiday-section {
  width: 60%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  /* background-color: #f9f9f9; */
  background-color: #fbfcfc;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Header styling with title and buttons */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.title {
    font-size: 1.2em;
    font-weight: bold;
    width: 100%;
    text-align: start;
}

.actions {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: flex-end;
    gap: 10px;
}

.add-btn, .import-btn {
    background-color: #f28c28;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.import-btn {
    background-color: #357aff;
}

.add-btn:hover, .import-btn:hover {
    opacity: 0.9;
}

/* Styling for the leave table */
.leave-table1 {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.leave-table th, .leave-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.leave-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.leave-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.leave-table tr:hover {
    background-color: #ddd;
}

/* Modal styles omitted (same as provided) */

  
  /* Modal container - full screen overlay */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it appears on top of everything */
  }
  
  /* Modal content box */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  /* Input field inside modal */
  .modal-content input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Modal buttons container */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  /* Individual buttons in modal */
  .modal-buttons button {
    background-color: #357aff; /* Blue color */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 45%;
  }
  
  /* Cancel button with different color */
  .modal-buttons button:nth-child(2) {
    background-color: #f44336; /* Red color */
  }
  
  /* Button hover effect */
  .modal-buttons button:hover {
    opacity: 0.9;
  }
  