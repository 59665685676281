/* .container {
  display: flex;
 
} */

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.logo {
  font-size: 24px;
  margin-bottom: 40px;
}

.nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.nav li {
  padding: 15px 20px;
  text-align: left;
  cursor: pointer;
}

.nav li:hover, .nav li.active {
  background-color: #34495e;
}

.main {
  flex: 1;
  padding: 10px;

  position: relative;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(128, 128, 128, 0.211);
  background-color: #f8f9fa;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
}

.createButton {
  background-color: #29295FFF;
  color: white;
  border: 1px solid #29295FFF;
  padding: 12px 20px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.createButton:hover {
  background-color: #34495e;
}

.projectList {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.project {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.projectNumber {
  width: 15%;
  font-weight: 500;
  /* text-align: center; */
}

/* .projectName span   {
  border: none;
  background: none;
  width: 150px;
  font-size: 16px;
  text-align: left;
} */
.projectName {
  width: 20%; /* Ensure it takes the full width */
  text-align: left; /* Align text to the left for the container */
  font-weight: 500;

}

.projectName span {
  display: inline-block; /* Ensures span acts like a block for text-align to work */
  width: 100%; /* Adjust to take full width if needed */
  text-align: left; /* Explicitly set text alignment */
  border: none;
  background: none;
  font-size: 14px;
  /* Add padding or margin if needed to adjust alignment visually */
}
.projectName1 {
  width: 10%; /* Ensure it takes the full width */
  text-align: left; /* Align text to the left for the container */
  font-weight: 500;

}

.projectName1 span {
  display: inline-block; /* Ensures span acts like a block for text-align to work */
  width: 100%; /* Adjust to take full width if needed */
  text-align: left; /* Explicitly set text alignment */
  border: none;
  background: none;
  font-size: 14px;
  /* Add padding or margin if needed to adjust alignment visually */
}
.projectActions {
  display: flex;
  gap: 10px;
}

.projectActions .icon {
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  color: black;
  /* background-color: ; */
}

.projectActions .icon:hover {
  color: #007bff; /* Highlight color on hover */
}

.projectDates {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 500;

}

.projectUsers {
  text-align: center;
  font-weight: 500;

}

.projectStatus {
  display: flex;
  align-items: center;
}

.statusIndicator {
  width: 15px;
  height: 15px;
  background-color: green;
  border-radius: 50%;
}


