.container {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #f5f5f5;
  padding: 20px;
}

.sidebar .logo img {
  width: 100%;
  margin-bottom: 20px;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
}

.sidebar nav ul li {
  margin-bottom: 10px;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.sidebar nav ul li a:hover,
.sidebar nav ul li.active a {
  color: #007bff;
}

.mainContent {
  flex: 1;
  padding: 20px;
}

.mainContent h1 {
  margin-bottom: 10px;
}

.taskSection {
  display: flex;
  justify-content: space-between;
}

.taskCategory, .taskType {
  width: 100%;
  /* margin-right: 5px; */
}

.taskCategory input, .taskType input {
  width: calc(100% - 50px);
  padding: 10px;
  margin-bottom: 10px;
}

.taskCategory button, .taskType button {
  padding: 10px;
}

.taskCategory ul, .taskType ul {
  list-style: none;
  padding: 0;
}

.taskCategory ul li, .taskType ul li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 5px;
}

.taskCategory ul li button, .taskType ul li button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.liContainer {
  width: auto;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
  list-style-type: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgb(80, 79, 79);
  font-size: 16px;
}

.liContainer:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #2c3e50a6 !important; /* Change this to your desired color */
  color: #ffffff; /* Optional: Change text color if needed */
}

.taskContainer {
  width: auto;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
  list-style-type: none;
}

.taskContainer:hover {
  background-color: #f0f0f0;
}

.selectedTask {
  background-color: #d0d0d0;
}
